//api地址
// const HOST = "http://192.168.1.131:8183/";
const HOST = "https://api.tongxiangcar.com/h5/";
// const HOST = "https://test.api.tongxiangcar.com/h5/";

const Apis = {
    //获取最新版本
    version: HOST + 'version/check_update',
    versionList: HOST + 'version/version_list',
};
export default Apis
