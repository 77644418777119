<template>
    <div class="content">
        <div style="width: 100%; display: flex; flex-direction: column">
            <img src="../assets/logo.png" style="width: 100px; height: 100px; margin: 80px auto auto;">
            <div style="font-size: 18px; font-weight: bold; margin: 20px auto auto;">同乡出行</div>
            <div style="font-size: 13px; margin: 10px auto auto;">v{{versionData.android_version}}</div>
            <div style="width: 80%; display: flex; margin: 80px auto auto; border-radius: 25px; height: 40px; background-color: dodgerblue;"
                v-if="isAndroid || !isAndroid && !isIos" @click="downloadAndroid()">
                <div style="margin: auto; color: white">Android下载</div>
            </div>
            <div style="width: 80%; display: flex; margin: 20px auto auto; border-radius: 25px; height: 40px; background-color: black;" v-if="isIos || !isAndroid && !isIos" @click="openIos()">
                <div style="margin: auto; color: white">Apple下载</div>
            </div>
        </div>
        <div class="mask" v-if="mask" @click="mask = false">
            <div style="top: 0px; right: 10px; position: fixed">
                <img src="../assets/plane.png" style="width: 40px; height: 40px;">
            </div>
            <div style="top: 60px; right: 10px; position: fixed; font-size: 15px; color: white">
                点击右上角在默认浏览器中打开
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "version_update",
        data() {
            return {
                isAndroid: false,
                isIos: false,
                isWechat: false,
                versionData: {},
                mask: false
            };
        },
        created () {
            document.title = 'APP下载';
            this.getDevice();
            this.getVersion();
        },
        methods: {
            getDevice(){
                const that = this;
                const u = navigator.userAgent;
                that.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
                that.isIos = u.indexOf('iPhone') > -1 || u.indexOf('iPad') > -1
                that.isWechat = u.indexOf('MicroMessenger') > -1
            },
            getVersion(){
                const that = this;
                this.$request.getVersion({}).then((data) => {
                    console.log(data);
                    that.versionData = data;
                });
            },
            downloadAndroid(){
                const that = this;
                if(!this.isWechat){
                    window.open(this.versionData.android_download_url);
                }else {
                    that.mask = true;
                }
            },
            openIos(){
                const that = this;
                if(!this.isWechat){
                    window.open(this.versionData.ios_download_url);
                }else {
                    that.mask = true;
                }
            }
        }
    }
</script>

<style scoped>
    .content {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .mask {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.2);
    }
</style>
