<template>
    <div class="content">
        <div style="font-size: 20px; font-weight: bold;">我们上线啦！！！</div>
        <img src="../../assets/images/activity/2.jpg" style="margin-top: 30px; width: 100%"/>
    </div>
</template>

<script>
    export default {
        name: "activity_1",
        created () {
            document.title = '我们上线啦！！！';
        },
    }
</script>

<style scoped>
    .content {
        display: flex;
        width: 100%;
        height:100%;
        text-align: center;
        flex-flow: column;
    }
</style>
