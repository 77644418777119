import Apis from "../api/api";
import axios from 'axios'
import {Message} from 'element-ui';

const METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
};


/**
 * 网络请求
 * @param method 方法
 * @param url 接口地址
 * @param params 参数
 * @param showError 是否展示错误信息
 * @returns {Promise<any>}
 */
// 错误和失败信息都在这里进行处理，界面中调用的时候只处理正确数据即可
function request(method, url, params, showError) {
    if (showError || showError === undefined){ // 是否展示错误信息
        showError = true;
    }else {
        showError = false;
    }
    console.log(url);
    console.log(params);
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: url,
            params: params,
            headers: {'token': localStorage.getItem("token")}
        }).then((res) => {
            console.log(res.data);
            if (res.data.err_code === 0) { // 0 是请求成功
                resolve(res.data.data);
            } else { // 其他情况返回错误信息，根据需要处理
                reject(res.data);
                if (showError){
                    Message.error(res.data.err_msg);
                }
            }
        }).catch(() => {
            if (showError){
                Message.error('请求失败，请稍后再试');
            }
        });
    });
}

/**
 * 图片上传
 * @param url 地址
 * @param params 参数 FormData
 * @param showError 是否展示错误
 * @returns {Promise<any>}
 */
// function uploadRequest(url, params, showError) {
//     if (showError || showError === undefined){
//         showError = true;
//     }else {
//         showError = false;
//     }
//     let config = {
//         headers: { "Content-Type": "multipart/form-data;boundary = "+ new Date().getTime(),'token': localStorage.getItem("token")},
//     };
//     console.log(url);
//     console.log(params);
//     return new Promise((resolve, reject) => {
//         axios.post(url,params,config).then((res) => {
//             console.log(res.data);
//             if (res.data.err_code === 0) { // 0 是请求成功
//                 resolve(res.data.data);
//             } else { // 其他情况返回错误信息，根据需要处理
//                 reject(res.data);
//                 if (showError){
//                     Message.error(res.data.err_msg);
//                 }
//             }
//         }).catch(() => {
//             if (showError){
//                 Message.error('请求失败，请稍后再试');
//             }
//         });
//     });
// }

function get(url, params, showError) {
    return request(METHOD.GET, url, params, showError);
}

// function post(url, params, showError) {
//     return request(METHOD.POST, url, params, showError);
// }

// function del(url, params, showError) {
//     return request(METHOD.DELETE, url, params, showError);
// }
//
// function put(url, params, showError) {
//     return request(METHOD.PUT, url, params, showError);
// }
//
// function upload(url, params, showError) {
//     return uploadRequest(url, params, showError);
// }
const API = {
    // 登录
    getVersion: (params) => get(Apis.version, params,true),
    //版本历史
    getVersionList: (params) => get(Apis.versionList, params,true),
    getFaceApp: (params) => get('https://yk.cetset.com/ksApi/ks/app/getFaceApp', params,true),
};

function install(Vue) {
    Vue.prototype.$request = API;
}
export default install
