import Vue from 'vue'
import Router from 'vue-router'
import RegistrationAgreement from "@/components/RegistrationAgreement";
import PrivacyPolicy from "@/components/PrivacyPolicy";
import VersionUpdate from "@/components/VersionUpdate";
import ThirdDirectory from "@/components/ThirdDirectory";
import VersionList from "@/components/VersionList";
import Activity1 from "@/components/activity/Activity1";
import Activity2 from "@/components/activity/Activity2";
import BecomeOwner from "@/components/BecomeOwner";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/registration_agreement'
    },
    {
      path: '/registration_agreement',
      name: 'registration_agreement',
      component: RegistrationAgreement
    },
    {
      path: '/privacy_policy',
      name: 'privacy_policy',
      component: PrivacyPolicy
    },
    {
      path: '/version_update',
      name: 'version_update',
      component: VersionUpdate
    },
    {
      path: '/third_directory',
      name: 'third_directory',
      component: ThirdDirectory
    },
    {
      path: '/version_list',
      name: 'version_list',
      component: VersionList
    },
    {
      path: '/activity_1',
      name: 'activity_1',
      component: Activity1
    },
    {
      path: '/activity_2',
      name: 'activity_2',
      component: Activity2
    },
    {
      path: '/become_owner',
      name: 'become_owner',
      component: BecomeOwner
    }
  ]
})
