<template>
    <div class="content">
        <div style="font-size: 16px; font-weight: bold; margin: auto">
            隐私政策
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            同乡出行平台非常重视用户隐私的保护。本隐私政策适用于同乡出行手机客户端和开放平台产品及服务。（包括但不限于APP、同乡出行顺风车小程序）。请您务必认真阅读本政策，
            在确认充分了解并同意后使用同乡出行。如果您/您的监护人不同意本隐私政策的任何内容，您应该立即停止使用。当您开始使用同乡出行，
            即表示您/您的监护人已经同意我们按照本政策来收集、保存、使用、共享、保护您的个人信息。<br />
            本隐私政策主要包括以下内容：<br /><br />
            1.本隐私政策将同乡出行平台的业务功能分为核心业务功能、附加业务功能及第三方提供的服务，并依次向您说明每项功能可能收集的个人信息范围、收集目的、收集方式，以及拒绝提供个人信息可能导致的结果。
            同乡出行平台将严格遵循合法、正当、必要的原则，收集、使用用户信息。<br /><br />
            2.本隐私政策将向您说明同乡出行平台可能需要调取的手机权限、对应的业务功能、调用目的、调用前是否询问以及用户关闭相应权限的方式。您同意本隐私政策后，相应手机权限并不会默认开启，
            当涉及重要或敏感的手机权限时，我们会在您使用到相应功能时，另行弹窗再次征得您的同意后开启。权限开启后，您还可以随时通过设备设置权限关闭。<br /><br />
            3.个人信息的使用规则，以及何种情况下，个人信息会被共享、转让和公开披露。<br /><br />
            同乡出行平台将严格按照本隐私政策说明的场景使用或者共享您的个人信息，如果超出本隐私政策说明的范围，我们会再次向您告知并征得您的明示同意。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            1. 个人信息的定义与范围
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1.1个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息。<br /><br />
            1.2个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<br /><br />
            1.3匿名化处理后的信息，即通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后不能被复原的信息，匿名化处理后的信息不属于个人信息。<br /><br />
            1.4其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            2. 个人信息的收集
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            2.1 同乡出行平台业务功能分为核心业务功能、附加业务功能及第三方提供的服务，以下说明我们将详细向您说明各业务功能所收集的个人信息以及收集方法、目的，拒绝提供所带来的影响等。
            业务功能具体包括：<br /><br />
            2.1.1 核心业务功能：创建账号并完善资料，顺风车，交易支付，数据分析及产品开发。<br /><br />
            2.1.2 附加业务功能：车主认证、用户分级、客服及各类运营活动。<br /><br />
            2.1.3 第三方提供的服务：车主服务。<br /><br />
            2.2 您在使用同乡出行平台服务前，需要在同乡出行平台创建账号并完善个人资料。通过用户主动提供的方式，会对用户收集以下信息：<br /><br />
            2.2.1 用户在注册账户时需要提供相应手机号码，可设置用户密码。我们根据用户提供的此类网络身份为用户提供平台相应的业务功能。 若您以其他关联账号登录，我们会向关联第三方请求您的个人信息，
            对于我们要求但第三方无法提供的信息，我们仍可能会要求您提供：<br /><br />
            a、如果您使用的是“微信登录”我们会获取您的OpenID、微信头像、微信昵称；<br /><br />
            b、当您使用Apple ID登陆时，我们会获取您的Apple ID的电子邮箱地址；<br /><br />
            c、如您使用“一键登录”的功能，基于我们与通信运营商的合作，我们会获取您的 手机号码和手机运营商信息，以便为您提供快捷的登录服务。<br /><br />
            为了满足网络实名制要求，如果您拒绝提供手机号码，我们平台仍将无法提供服务。<br /><br />
            2.2.2 用户还可以选择性地向同乡出行平台提供医疗卡信息。 如用户可以提供该信息，在遇到突发事件时可得到更及时的治疗。<br /><br />
            2.2.3 用户想要注册成为顺风车车主，必须向我们提供 姓名、身份证、面部识别特征、车辆品牌、型号、颜色、车辆照片、车牌号、驾驶证、行驶证、银行卡信息、常驻地址（家、公司）。
            平台收集以上信息是基于法律法规要求以及保护平台用户安全的原则下。如果用户拒绝提供上述信息，将不能成为同乡出行平台认证车主。<br /><br />
            2.2.4 用户可以选择是否提供常驻地址（家、公司）， 平台收集这两个信息是为了让用户更加快捷的发布行程，提高服务效率。如果拒绝提供地址信息，则需要用户手动添加起终点信息手动发布行程。<br /><br />
            2.2.5 用户可以选择添加安全联系人，并设置自动分享行程的时间。添加安全联系人时，需要手动输入联系人姓名、手机号。添加联系人后，处于自动分享行程时间内或使用一键报警功能时，
            行程信息会以短信形式发送给安全联系人。<br /><br />
            2.3 用户在使用顺风车服务时，我们还会收集以下信息：<br /><br />
            2.3.1 身份信息，为满足法律法规要求、更好保护用户人身财产安全以及满足平台风控要求，您使用上述功能前必须提供身份信息，如使用顺风车前需提供姓名、身份证、面部识别特征。如您拒绝提供上述信息，
            同乡出行平台将无法为您提供相关服务。<br /><br />
            2.3.2 行程信息，我们将通过记录用户行为收集用户的出发地、到达地、行程轨迹、时长及里程数信息。收集上述信息是基于法律法规范围内保障用户自身安全使用、依照平台规则处理用户纠纷。
            如果拒绝提供出发地、到达地信息，顺风车车主将无法为用户提供接驾服务，无法获取用户实时位置以及更好地保障用户安全。<br /><br />
            2.3.3 录音信息，为提升对用户的安全保障能力且更好地处理用户之间的纠纷。同乡出行App上线了录音取证功能，详细规则见《录音信息收集协议》。<br /><br />
            2.4 用户可以在平台用车结束后进行服务满意评价和文字评价，平台将对用户的个人信息做匿名处理后呈现相关评价内容。<br /><br />
            2.5 当用户与平台客服人员联系时，平台会以通话录音及人工录入的方式记录用户的通讯信息、提出的意见或建议、姓名、联系方式，以便平台更好地处理纠纷、解决问题并向用户返回处理结果。<br /><br />
            2.6 用户在参加平台组织的运营活动时，我们可能会需要用户提供某些信息，包含姓名、手机号、通讯地址。这些信息有助于我们对活动结果进行公示、活动进行后续宣传，及与用户取得联系，发放奖品或转账。
            如果用户拒绝提供上述信息，可能导致无法参加相应运营活动，或无法收到平台寄出的礼品、转账，但不会影响您使用同乡出行平台其他业务功能。此外，如您之前退订了营销短信功能，
            也可能无法收到营销活动相关短信内容。如需取消退订，请联系平台客服进行修改。<br /><br />
            2.7 在您使用APP（静默后台运行或启动阶段），在您注册账号、完成下单、参加运营活动、浏览页面时等，为了防控运营风险，保障您的账号安全，满足法律法规要求，更好改善同乡出行平台服务，
            我们会收集您的设备信息（IMEI、IMSI、mac、android_id、deviceid、idfa、设备型号、分辨率、系统版本）；为了防止恶意程序，保障服务安全与提升乘车体验，
            我们会收集安装的应用程序或正在运行的进程信息、应用程序的总体运行、使用情况与频率，应用崩溃情况，总体安装使用情况、性能数据、应用来源；为了便捷的搜索结果以及网络异常情况下保障顺利出行，
            我们会收集同乡出行平台内的日志信息（包括浏览记录、检索内容、点击查看记录、交易记录以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间）；为了存储信息、读取存储信息，
            我们会收集存储信息；并按照法律法规对保存时限的要求，对上述信息进行处理。单独设备信息和日志信息等是无法识别特定自然人身份的，如果我们将这类非个人信息与其他信息结合用于特定自然人身份，
            或将个人信息将被视为个人信息，按照个人信息使用规定使用。<br /><br />
            2.8 用户在使用同乡出行平台以上各项功能时，我们还会收集以下信息：<br /><br />
            2.8.1 位置信息，以便用户不需要手动输入地理坐标即可获取相关的服务以及提供安全保障（包括但不限于司乘同显、安全护航等）。
            我们会通过IP地址、GPS以及能够提供相关信息的其他传感器来获取用户的位置信息。如果用户拒绝提供位置信息，不能完成下单功能。<br /><br />
            2.8.2 订单信息及交易状态。基于法律法规要求、用户安全保障及解决用户纠纷的需要，我们会记录用户的订单信息及交易状态。<br /><br />
            2.8.3 通过同乡出行平台接打电话、发送即时通讯信息时，我们会收集用户的通话内容、主被叫手机号码信息，以及即时通讯的文字信息。<br /><br />
            2.9 用户在使用同乡出行平台第三方服务时授权同乡出行平台共享给第三方的个人信息，将适用第三方的隐私保护政策。当您查看第三方创建的网页或使用第三方开发的应用程序时，
            这些第三方可能放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们控制，且他们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，
            但我们无法保证这些主体一定会按照我们的要求采取保护措施。请您在使用前仔细阅读他们的隐私保护政策，以判断是否继续使用该第三方服务。<br /><br />
            2.10 请用户理解，我们向用户提供的功能和服务是不断更新和发展的，平台任何一项需要获取用户信息的服务，会以页面提示、消息通知或签署用户协议等方式及时告知用户并获得用户同意。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            3. 设备权限调用
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            3.1 同乡出行平台可能会调用您的一些设备权限，以下是调用权限对应的业务功能、我们调用权限的目的，以及调用权限前向您询问的情况。<br /><br />
            3.2 您可以在设备的设置功能中选择关闭部分或全部权限，从而拒绝同乡出行收集相应的个人信息。使用Android系统的不同设备中，权限显示方式及关闭方式可能有所不同，具体请联系设备及系统开发方。<br /><br />
            3.3 《设备权限调用列表》<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            4. 我们如何使用Cookie及同类技术
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            4.1 Cookie是支持服务器端(或者脚本)在客户端上存储和检索信息的一种机制。当您使用同乡出行产品或服务时,为使您获得更轻松安全的访问体验,我们可能会使用Cookie或同类技术来收集和存储信息,
            在此过程中可能会向您的设备发送一个或多个Cookie或匿名标识符。这么做是为了收取您的信息,用于了解您的偏好,进行咨询或数据分析,改善产品服务及用户体验,或及时发现并防范安全风险,
            为您提供更好的服务。我们不会将Cookie用于本政策所述目的之外的任何用途,您可根据自己的偏好留存或删除Cookie。您可清除软件内保存的所有 Cookie,当您手动清除后您的相关信息即已删除。<br /><br />
            4.2 我们平台上还可能包含一些电子图像(以下简称“单像素GF文件或“网络Beacon"),使用网络Beacon可以计算浏览网页的用户或访问某些Cookie,我们会通过网络Beacon收集您浏览网页活动的信息,
            例如: Internet协议(IP)地址,浏览器类型,Internet服务提供商(ISP),参考退出页面,操作系统,日期/时间戳,和点击数据流等。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            5. 个人信息的保存
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            5.1 信息保存期限<br /><br />
            5.1.1 在用户使用同乡出行平台服务期间，我们会持续保存用户的个人信息。<br /><br />
            5.1.2 当您自主删除个人信息或注销账户，我们将按照法律、法规规定的最短期限保留您的现有个人信息。当您的个人信息超出上述保存期限，我们将会按照法律、法规的要求进行处理。<br /><br />
            a. 为配合人民检察院、公安机关、国家安全机关侦查用户使用同乡出行服务过程中产生的犯罪行为，更好保护其他用户生命财产安全，当用户自主删除个人信息或注销账户，我们将在刑法规定的犯罪追诉时效期间内，
            加密隔离存档用户个人信息。<br /><br />
            b. 如您使用同乡出行平台服务的过程中，存在严重违反法律法规、平台协议、平台规则等情形，您的违法、违约记录及相应的平台信用记录，将被永久保存。<br /><br />
            5.2 信息存放地域<br /><br />
            5.2.1 我们收集的您的个人信息，将在中国内地存储和使用。<br /><br />
            5.3 安全保护措施<br /><br />
            5.3.1本公司将采用严格的安全制度以及行业通行的安全技术和程序来确保您的个人信息不丟失、泄露、毁损或被未经授权的访问、使用。<br /><br />
            5.3.2 同乡出行平台采取以下安全技术措施保护您的个人信息：<br /><br />
            a. 用户个人信息被加密储存在服务器中，并通过数据隔离技术进行存储。<br /><br />
            b. 数据传输过程中使用加密传输协议。<br /><br />
            c. 严格控制数据访问权限，设立完善的敏感数据访问权限申请、审批制度。<br /><br />
            d. 建立数据安全监控和审计制度，进行全面数据安全控制。<br /><br />
            5.3.3 同乡出行平台同时采取其他安全措施保护您的个人信息：<br /><br />
            a. 建立公司信息安全委员会，统一协调管理数据安全工作，并下设数据安全小组、推动各项数据安全活动。<br /><br />
            b. 公司内部颁布实施数据安全管理规范，明确对用户数据（包括用户个人信息）的保护标准和要求。<br /><br />
            c. 新项目、新系统上线前对数据安全（包括用户个人信息数据）进行项目风险评估。<br /><br />
            d. 与全体员工及服务外包人员签署保密协议，并严格按照工作职责分配数据访问权限。<br /><br />
            e. 定期开展面向公司全体员工及外包服务人员的信息安全教育及培训。<br /><br />
            5.4 信息安全事件处置<br /><br />
            5.4.1 为应对个人信息泄露、毁损、丟失等可能出现的安全风险，已经在公司范围内颁布多项制度，明确了安全事故、安全漏洞的分类分级标准，以及针对上述安全事故和漏洞，
            内部处理流程（包括应急响应流程）和补救措施。<br /><br />
            5.4.2 —旦发生安全事件，我们将及时向您告知事件基本情况和风险、我们已经采取或将要采取的措施、您如何自行降低风险的建议等。<br /><br />
            5.4.3我们将及时以推送通知、信函或电话等方式将安全事件情况通知受影响的用户。当难以逐一告知用户时，我们将通过发布平台公告的方式发布警示信息。<br /><br />
            5.5 停止运营<br /><br />
            5.5.1 如同乡出行平台停止运营，我们将至少提前30日在同乡出行平台发布公告，并及时停止收集个人信息。<br /><br />
            5.5.2 停止运营后，我们将在满足法律法规规定的最短保存期后，对收集的个人信息按照法律、法规的要求进行处理。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            6.个人信息的使用
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            6.1 您的个人信息会被用于“个人信息的收集”条款明确列明的使用场景。<br /><br />
            6.2 您的个人信息可能被用于以下与“个人信息的收集”条款所声称的目的具有直接或合理关联的场景：<br /><br />
            a. 我们可能将来自同乡出行平台某项服务的个人信息与来自其他项服务的信息结合起来，作出特征模型并进行数据融合，以为您提供更加便捷的服务。<br /><br />
            b. 我们可能以用户个人信息统计数据为基础设计、开发、推广全新的产品及服务。<br /><br />
            c. 我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息。<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            7. 个人信息的共享、转让、公开披露
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            7.1 共享是指同乡出行向其他个人信息控制者提供个人信息，且双方分别对个人信息拥有独立控制权的过程。同乡出行不会对外共享您的个人信息，但以下情况除外：<br /><br />
            7.1.1 在获取明确同意情况下的共享：获得您的明确同意后，同乡出行会向您指定的第三方共享您授权范围内的信息。<br /><br />
            7.1.2 在法定情形下的共享：同乡出行可能会根据法律法规规定、诉讼争议解决需要，或按照行政、司法机关依法提出的要求，对外共享您的个人信息。<br /><br />
            7.1.3 共享给业务合作伙伴：同乡出行可能会向合作伙伴等第三方共享您的个人信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，
            并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括：<br /><br />
            a.您通过同乡出行平台使用第三方服务时，同乡出行会按照“个人信息收集”条款列明的共享内容与提供服务的第三方共享您的个人信息。<br /><br />
            b.广告、分析服务类的授权合作伙伴。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便不会识别您个人。
            这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。<br /><br />
            c.如果您通过同乡出行平台参加第三方举办或同乡出行与第三方联合举办的营销、推广活动，需单独授权个人信息使用。同乡出行将根据您的授权，与第三方共享您的最小必要个人信息，以便第三方统计活动参与情况、获奖情况，
            为您绑定您获取的优惠券、发放权益、邮寄您获取的奖品等。<br /><br />
            d.供应商、服务提供商或其他合作伙伴。同乡出行可能会将您的个人信息共享给支持我们功能的第三方，包括为我们提供基础设施技术服务、实名验证服务及其他身份验证服务、人脸识别服务、支付服务、数据处理服务的第三方等。
            我们共享这些信息的目的是为实现产品及服务的功能，比如我们需要将您的姓名和身份证号共享给实名验证服务商，以进行实名验证；或者我们需要与人脸识别供应商共享您的面部识别特征及姓名、身份证号，
            以识别您是否本人使用服务；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付；或者我们需要将您的IMEI和Mac地址等个人信息发送给第三方机构，
            用以识别您的设备信息等。<br /><br />
            7.2 为维护用户合法权益，在协助处理与您有关的交易纠纷或争议时，我们可能向您的交易相对方或存在利害关系的第三方提供解决交易纠纷或争议所必需的信息。<br /><br />
            7.3 我们将遵守相关法律法规，对您的个人信息予以保密。除非事先获得您的明确同意或授权，或依照法律规定所必须，我们不会公开披露您的个人信息。<br /><br />
            7.4 根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让或公开披露个人信息无需事先征得个人信息主体的授权同意：<br /><br />
            a. 与国家安全、国防安全直接相关的；<br /><br />
            b. 与公共安全、公共卫生、重大公共利益直接相关的；<br /><br />
            c．与犯罪侦查、起诉、审判和判决执行等直接相关的；<br /><br />
            d．出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br /><br />
            e．所收集的个人信息是个人信息主体自行向社会公众公开的；<br /><br />
            f．从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br /><br />
            7.5 如发生并购、兼并、重组等变更，我们会要求变更后的主体依然遵守本隐私政策的约定，履行原有责任及义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明示同意。对内而言，
            在之前的收购、兼并活动中，对于被收购、被兼并对象管理的数据（包括用户数据）均采用与同乡出行自有数据相同的标准和要求进行处理和保护。<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            8. 应用自启动
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; ">
            8.1 百度人脸识别：包名com.baidu.liantian，百度人脸识别SDK自启动提前初始化相关的功能，给用户带来更好的人脸识别体验。<br /><br />
            8.2 通知栏通知SDK：包名com.dexterous.flutterlocalnotifications，通知栏通知SDK自启动是为了收到消息时及时展示给用户。<br /><br />
            8.3 极光推送SDK：包名cn.jpush.android，极光推送自启动是为了保持应用内长链接保活以保证能准确的接收到消息。<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            第三方SDK目录
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            为保障同乡出行Ap相关功能的实现与应用的稳定运行，我们可能会接入由第三方提供的软件开发包(SDK) 以实现相关目的。不同版本的第三方SDK会有所不同，一般包括第三方账号登录类、推送通知消息类、第三方支付类、
            地图导航类、厂商推送类、分享类、统计类等。我们会对合作方获取信息的软件工具开发包(SDK)进行严格的安全监测，以保障数据安全。您可以通过以下链接查看第三方的数据使用和保护规则。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  第三方支付
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:根据用户选择、为用户提供第三方支付功能。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备的国际移动设备识别码(IMEI)、设备Mac地址、设备型号信息、WiFi信息、SIM卡状态。<br /><br />
        </div>
        <div style="font-size: 15px; margin-top: 10px;">
            第三方隐私政策链接.<br /><br />
            微信: https://www.wechat.com/zh CN/privacy_policy.html<br /><br />
            支付宝: https://render. alipay.com/p/c/k2cx0tg8
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  人脸识别
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:保证账户实际使用人与注册登记人信息相符，保证乘车安全。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:手机硬件型号、操作系统版本、设备配置、唯-设备标识符、国际移动设备身份码IMEI、IDFA、网络设备硬件地址MAC、摄像头状态、正在运行的进程信息<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            百度人脸识别: https://ai.baidu.com/ai-doc/REFERENCE/Akdyizf5t
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  地图导航
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            高德地图: htps://s.amap.comn/pages/privacy/
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:存储信息，地理位置信息、WIFI信息、设备的国际移动设备识别码(IMEI)、mac地址、加速度传感器。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            高德地图: htps://s.amap.comn/pages/privacy/
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  推送通知消息
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            极光推送<br /><br />
            使用目的:为用户推送相关信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/BSSID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            极光推送: https://www.jiguang.cn/license/privacy<br /><br />
            小米推送<br /><br />
            使用目的:为用户推送相关信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            极光推送: https://privacy.mi.com/all/zh_CN/<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  数据统计
        </div>
        <div style="font-size: 13px; bold; margin-top: 10px">
            友盟
            使用目的:数据统计分析。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; bold; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            友盟APM: https://www.umeng.com/page/privacy<br /><br />
            腾讯Bugly
            使用目的:数据统计分析。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; bold; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            腾讯Bugly: https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  第三方登录
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            微信登录<br /><br />
            使用目的:根据用户选择为用户提供第三方账户登录功能。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            微信: https://www.wechat.com/zh_CN/privacy_policy.html<br /><br />
            一键登录<br /><br />
            使用目的:根据用户SIM卡开启的数据流量类型及运营商获取SIM卡卡号，进行免输验证码一键登录。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:存储信息、设备信息、应用列表信息、网络信息、加密后的登录信息以及位置相关信息、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            阿里号码认证: https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html?spm=a2c4g.11186623.0.0.3c6936c7m0ZPs2
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  信息分享
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:根据用户选择为用户提供江内容分享至微信的功能。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px; font-weight: bold; color: red;">
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            第三方隐私政策链接<br /><br />
            微信: https://www.wechat.com/zh_CN/privacy_policy.html
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            9. 用户权利
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            9.1 用户有权查看个人的信息；<br /><br />
            账户：你可以通过进入首页-点击头像-进入侧栏-点击头像，访问你的个人信息；包含昵称、实名认证状态、信用分、收到的评价、车主认证信息等情况。<br /><br />
            订单：你可以通过进入首页-点击头像-进入侧栏-点击乘车记录，访问你的行程信息、支付信息、交易状态。<br /><br />
            安全：你可以通过进入首页-点击头像-进入侧栏-点击安全中心，访问你设置的紧急联系人、实名认证情况、行程录音、医疗急救卡等设置情况。<br /><br />
            钱包：你可以通过进入同乡出行平台首页-点击头像-进入侧栏-点击钱包，访问你的余额、零钱包、顺风车费、顺风金、优惠券、绑定银行卡、支付设置等信息。<br /><br />
            9.2 用户有权更改个人的信息；<br /><br />
            账户：你可以通过进入首页-点击头像-进入侧栏-点击头像，更正或修改你提供的个人资料信息。<br /><br />
            设置：你可以通过进入首页-点击头像-进入侧栏-点击设置，更正或修改你的账号密码、家和工作地、注销账户等信息以及操作。<br /><br />
            钱包：你可以通过进入首页-点击头像-进入侧栏-点击钱包，更正或修改你的绑定银行卡、支付设置等信息<br /><br />
            9.3 用户有权删除个人的信息；<br /><br />
            您可以通过联系同乡出行平台的人工客服申请对您的个人信息进行删除。<br /><br />
            9.4 用户有权改变用户授权平台使用的范围；<br /><br />
            您可在您的设备的 “设置-应用权限”中查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，
            您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。<br /><br />
            9.5 用户有权注销自己的账户;<br /><br />
            你可以通过进入首页-点击头像-进入侧栏-点击设置，在设置中进行注销账号。<br /><br />
            若您通过同乡出行平台指引不能完成上述操作，可联系同乡出行客服。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            10. 您共享的信息
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            10.1 您可以基于同乡出行平台的服务与他人、社交网络（如有）分享您的位置、行程信息，并自主选择分享的对象，作为能够看到您的位置、行程信息的第三方。<br /><br />
            10.2 当您与他人、社交网络或第三方服务提供商共享您的位置、行程或其他个人信息，或结合第三方的服务使用、或使用第三方的服务链接时，您可能会披露或使第三方获取您的个人信息。<br /><br />
            10.3 我们建议您查看此类社交网络或第三方服务提供商的隐私声明，以了解他们如何处理您的信息。对于您使用第三方服务时向其提供或与他人分享个人信息而产生的后果由相应责任方在法律规定范围内承担责任。<br /><br />
            10.4 当您在同乡出行平台公开分享任何信息时，任何人都可能在使用同乡出行平台时查看或访问这些信息，如您在同乡出行平台上传的评价等信息。您在使用我们服务时自愿共享的信息，可能会涉及您或他人的个人信息，
            请您谨慎选择，若侵犯他人权利需依法承担相应的责任。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            11. 未成年人保护
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            11.1 同乡出行非常重视对未成年人信息的保护。如果您是未满18周岁的未成年人，您的监护人需要仔细阅读本政策并授权您同意依照本政策使用同乡出行平台。如果您是未满14周岁的未成年人，
            您的监护人需要仔细阅读本政策并同意您依照本政策使用同乡出行平台。<br /><br />
            11.2 如果您/您的监护人不同意本隐私政策的任何内容，您应该立即停止使用同乡出行平台。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            12. 变更
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            12.1 如同乡出行平台发生以下变化，我们将及时对本隐私政策进行相应的修订：<br /><br />
            a．同乡出行平台业务功能发生变更。<br /><br />
            b．用户个人信息保存地域发生变更。<br /><br />
            c．用户个人信息的使用规则发生变更。<br /><br />
            d．我们的联络方式及投诉渠道发生变更。<br /><br />
            e．发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等。<br /><br />
            12.2 隐私政策修订后，我们会在同乡出行平台发布最新版本，并以弹窗或推送通知的方式告知用户，以便用户及时了解最新版本隐私政策。<br /><br />
            12.3 未经您的明确同意，我们不会削减您按照本隐私政策所享有的权利。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            13. 声明
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            13.1 以上协议自2023年9月1日起生效。<br /><br /><br />
            广州一快科技有限公司<br /><br />
        </div>
    </div>
</template>

<script>
    export default {
        name: "privacy_policy",
        created () {
            document.title = '隐私政策'
        },
    }
</script>

<style scoped>
    .content{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
</style>
