<template>
    <div class="content">
        <img src: src="../assets/images/btn.png" style="width: 60%; height: 60px; position: absolute; bottom: 70px; margin-left: 20%; margin-right: 20%;" @click="open"/>
        <div class="mask" v-if="mask" @click="mask = false">
            <div style="top: 0px; right: 10px; position: fixed">
                <img src="../assets/plane.png" style="width: 40px; height: 40px;">
            </div>
            <div style="top: 60px; right: 10px; position: fixed; font-size: 15px; color: white">
                点击右上角在默认浏览器中打开
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "become_owner",
        data() {
            return {
                isAndroid: false,
                isIos: false,
                isWechat: false,
                versionData: {},
                mask: false
            };
        },
        created () {
            document.title = '成为车主';
            this.getDevice();
            this.getVersion();
        },
        methods: {
            getDevice(){
                const that = this;
                const u = navigator.userAgent;
                that.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
                that.isIos = u.indexOf('iPhone') > -1 || u.indexOf('iPad') > -1
                that.isWechat = u.indexOf('MicroMessenger') > -1
            },
            getVersion(){
                const that = this;
                this.$request.getVersion({}).then((data) => {
                    console.log(data);
                    that.versionData = data;
                });
            },
            downloadAndroid(){
                const that = this;
                if(!this.isWechat){
                    window.open(this.versionData.android_download_url);
                }else {
                    that.mask = true;
                }
            },
            open(){
                const that = this;
                if(!this.isWechat){   
                    if(that.isAndroid){
                        window.open(that.versionData.android_download_url);
                    }else{
                        window.open(that.versionData.ios_download_url);
                    }              
                }else {
                    that.mask = true;
                }
            }
        }
    }
</script>

<style scoped>
    .content {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        background-image: url(../assets/images/become_owner_bg.jpg);
        background-size: 100vw 100vh;
    }
    .mask {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.2);
    }
</style>
