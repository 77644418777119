<template>
    <div class="content">
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            一、第三方SDK目录
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            为保障同乡出行Ap相关功能的实现与应用的稳定运行，我们可能会接入由第三方提供的软件开发包(SDK) 以实现相关目的。不同版本的第三方SDK会有所不同，一般包括第三方账号登录类、推送通知消息类、第三方支付类、
            地图导航类、厂商推送类、分享类、统计类等。我们会对合作方获取信息的软件工具开发包(SDK)进行严格的安全监测，以保障数据安全。您可以通过以下链接查看第三方的数据使用和保护规则。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  第三方支付
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:根据用户选择、为用户提供第三方支付功能。<br /><br />
            涉及个人信息:设备的国际移动设备识别码(IMEI)、设备Mac地址、设备型号信息、WiFi信息、SIM卡状态。<br /><br />
            第三方隐私政策链接.<br /><br />
            微信: https://www.wechat.com/zh CN/privacy_policy.html<br /><br />
            支付宝: https://render. alipay.com/p/c/k2cx0tg8
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  人脸识别
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:保证账户实际使用人与注册登记人信息相符，保证乘车安全。<br /><br />
            涉及个人信息:手机硬件型号、操作系统版本、设备配置、唯-设备标识符、国际移动设备身份码IMEI、IDFA、网络设备硬件地址MAC、摄像头状态、正在运行的进程信息<br /><br />
            第三方隐私政策链接<br /><br />
            百度人脸识别: https://ai.baidu.com/ai-doc/REFERENCE/Akdyizf5t
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  地图导航
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:为用户提供地图定位和导航功能，以及司乘同显功能。<br /><br />
            涉及个人信息:存储信息，地理位置信息、WIFI信息、设备的国际移动设备识别码(IMEI)、mac地址。<br /><br />
            第三方隐私政策链接<br /><br />
            高德地图: htps://s.amap.comn/pages/privacy/
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  推送通知消息
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:为用户推送相关信息。<br /><br />
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
            第三方隐私政策链接<br /><br />
            极光推送: https://www.jiguang.cn/license/privacy
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  数据统计
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:数据统计分析。<br /><br />
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
            第三方隐私政策链接<br /><br />
            友盟APM: https://www.umeng.com/page/privacy
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  第三方登录
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:根据用户选择为用户提供第三方账户登录功能。<br /><br />
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
            第三方隐私政策链接<br /><br />
            微信: https://www.wechat.com/zh_CN/privacy_policy.html<br /><br />
            使用目的:根据用户SIM卡开启的数据流量类型及运营商获取SIM卡卡号，进行免输验证码一键登录。<br /><br />
            涉及个人信息:存储信息、设备信息、应用列表信息、网络信息、加密后的登录信息以及位置相关信息、正在运行的进程信息。<br /><br />
            第三方隐私政策链接<br /><br />
            极光认证: https://www.jiguang.cn/license/privacy
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            ●  信息分享
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            使用目的:根据用户选择为用户提供江内容分享至微信的功能。<br /><br />
            涉及个人信息:设备标识符(IMEI/Mac/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、正在运行的进程信息。<br /><br />
            第三方隐私政策链接<br /><br />
            微信: https://www.wechat.com/zh_CN/privacy_policy.html
        </div>
    </div>
</template>

<script>
    export default {
        name: "third_directory",
        created () {
            document.title = '接入第三方目录'
        },
    }
</script>

<style scoped>
    .content{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
</style>
