<template>
    <div class="content">
        <img src="../../assets/images/activity/banner2.png" style="width: 100%;"/>
        <div style="margin: 30px; border: 1px solid #F0E68C; height: 300px; display: flex; flex-direction: column;">
            <label style="margin-left: auto; margin-right: auto; margin-top: 10px; font-size: 18px; font-weight: bold;">活动规则</label>
            <label style="margin-left: 20px; margin-top: 10px; font-size: 15px; color: green;">活动名称</label>
            <label style="margin-left: 20px; margin-top: 10px; font-size: 14px; color: gray;">过年返乡潮 接单领好礼</label>
            <label style="margin-left: 20px; margin-top: 20px; font-size: 15px; color: green;">活动说明</label>
            <label style="margin-left: 20px; margin-top: 10px; font-size: 14px; color: gray;">1、完成金额超过200元订单赠送5元车主券</label>
            <label style="margin-left: 20px; margin-top: 10px; font-size: 14px; color: gray;">2、每天最高可使用4张，共20元</label>
            <label style="margin-left: 20px; margin-top: 10px; font-size: 14px; color: gray;">3、每月最高可使用80张，共400元</label>
            <label style="margin-left: 20px; margin-top: 10px; font-size: 14px; color: gray; margin-right: 20px; margin-bottom: 20px;">4、完成订单后车主券金额直接返现到余额，可用于提现</label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "activity_2",
        created () {
            document.title = '';
        },
    }
</script>

<style scoped>
    .content {
        display: flex;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        flex-direction: column;
        background-color: #FFF9EF;
    }
</style>
