<template>
    <div class="content">
        <div style="font-size: 16px; font-weight: bold; margin: auto">
            注册协议
        </div>
        <div style="text-indent: 30px; font-size: 13px; margin-top: 10px">
            您使用“同乡出行”产品或服务前，请仔细阅读《同乡出行注册服务协议》(以下称“ 本协议”)后再进行登录/注册。本协议在您同意注册成为“同乡出行用户”时即已生效，
            并成为您和“同乡出行”之间具有法律约束力的文件。本协议具有正式合同的效力，您和“同乡出行”将同等、公平地受到本协议条款的约束。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            一、定义
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、“同乡出行”是指由广州一快科技有限公司独立开发并享有合法所有权的软件平台/网站/手机App客户端，“同乡出行”平台将为“同乡出行用户”提供合乘信息服务。<br /><br />
            2、“同乡出行用户”是指按照“同乡出行”的要求自愿完成注册的“同乡出行”用户。用户可以通过手机App、移动便携设备和网上注册成为“同乡出行”用户，
            享受“同乡出行”为用户提供的合乘信息服务(以下称为“同乡出行”或“您”)。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            二、“同乡出行用户”的注册
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、请您根据“同乡出行”用户注册的要求填写真实、完整的信息，并接受本协议后方可申请成为“同乡出行用户”。<br /><br />
            2、用户申请成为“同乡出行用户”的具体流程如下:<br /><br />
              ①第一步:下 载“同乡出行”客户端 ;<br /><br />
              ②客户端第二步:点击“同乡出行”客户端，进入“验证码登录/注册”界面;<br /><br />
              ③填写注册信息第三步:输入手机号码登录/注册，获取验证码;<br /><br />
              ④登录第四步:同意《用户协议》、《隐私条款》 及《合乘协议》，验证并登录，成为“同乡出行用户”。<br /><br />
            3、“同乡出行用户"在注册完成后应及时修改您的密码以保障您的信息安全和合法权益，您的ID和密码由您自行保管。禁止“同乡出行用户”将自己的ID及密码交付第三方使用，
            若第三方凭正确的“同乡出行用户"ID及密码进行账号操作，将被“同乡出行”视为该用户的自身行为，由该行为引起的一切法律后果由该用户自行承担。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            三、双方权利义务
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、“同乡出行用户"有权在“同乡出行”App客户端上响应或发布出行信息;<br /><br />
            2、“同乡出行”提供的出行信息服务;<br /><br />
            3、“同乡出行”用户信息管理系统;<br /><br />
            4、“同乡出行用户”有权随时查询出行信息的发布状态、匹配情况;<br /><br />
            5、“同乡出行”及时向用户发布最新出行信息，使“同乡出行用户”享受便捷、贴心的服务;<br /><br />
            6、“同乡出行”为保障“同乡出行用户”能享受更好、更贴心的服务，将不定期更新“同乡出行”客户端软件，为您增添更多的服务;<br /><br />
            7、“同乡出行”对“同乡出行用户”的个人信息非常重视，将竭力保护“同乡出行用户”隐私信息，公检法司等国家权力机关正当行使权力除外。
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            四、“同乡出行用户”的义务
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、“同乡出行用户”不得从事以下行为，若发生下列行为，则该用户应自行承担相应的法律责任。“同乡出行”有权限制或取消其用户权限:<br /><br />
            ①申请或者变更“同乡出行用户”信息时提供虚假、违法信息;<br /><br />
            ②盗用他人信息;<br /><br />
            ③利用任何方式方法危害“同乡出行"App客户端或网站系统的安全;<br /><br />
            ④利用“同乡出行”侵害第三方的合法权益;<br /><br />
            ⑤在“同乡出行”服务平台上复制、发布任何形式的虚假信息，或复制、发布含有下列内容的信息:<br /><br />
            a.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一;<br /><br />
            b.损害国家荣誉和利益;<br /><br />
            c.煽动民族仇恨、民族歧视，破坏民族团结;<br /><br />
            d.破坏国家宗教政策，宣扬邪教和封建迷信;<br /><br />
            e.散布谣言，扰乱社会秩序，破坏社会稳定;<br /><br />
            f.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪;<br /><br />
            g.含有法律、行政法规禁止的其他内容。<br /><br />
            ⑥有犯罪记录(包括交通肇事犯罪、危险驾驶犯罪、暴力犯罪以及其他犯罪行为)， 有吸毒记录,有饮酒后驾驶记录。<br /><br />
            2、在您注册成为“同乡出行用户”时，您应按照法律、法规的要求填写用户注册信息，或根据“同乡出行”注册页面的提示准确提供、并及时更新您的用户资料，以确保用户信息的真实、及时，
            完整及准确。如有合理理由认为“同乡出行用户”提供的资料/信息错误、不实或不完整，“同乡出行出行”有权向用户发出询问或要求其改正的通知，
            若被通知的“同乡出行用户"在“同乡出行”给予合理期限内仍未能书面说明或及时改正的，“同乡出行”有权直接删除相应资料，或中止/终止对“同乡出行用户”提供部分或全部的服务，
            也有权直接注销您的“同乡出行用户”ID，“同乡出行”对此无需承担任何责任。若“同乡出行用户”选择认证“同乡顺风车”车主，您应当符合认证城市的私人小客车合乘出行指导意见规定的准入标准，
            否则，相应的法律后果将由您独自承担。<br /><br />
            3、“同乡出行用户”通过“同乡出行”平台获取合乘信息后，在线上与司机方(或乘客方)达成合乘交易时，敬请该用户严格遵守中.国相关法律、法规、规章及行政规范性文件。
            “同乡出行”在司机方注册成为“同乡出行用户”时会严格要求其上传驾驶证等身份证明文件，但“同乡出行”只能对司机方的信息进行形式审核，无法进行实质审核，所以请您在线上与司机方达成合乘交易时，
            您可以通过“同乡出行”服务平台查看司机方的相关信息，同时也请您能在上车前能查看司机方的驾驶证等有效身份证明文件。<br /><br />
            4、“同乡出行用户”确认:在使用“同乡出行”产品或服务过程中，所产生的国家应纳税赋，以及硬件、软件、服务及其它方面的费用，均由您自行承担。<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            五、同乡出行的权利义务
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、“同乡出行”保证遵循其在“同乡出行”客户端上公布的服务承诺，努力创建高效便捷的出行信息服务平台。<br /><br />
            2、“同乡出行”在司机方进行身份认证时会要求其上传驾驶证、行驶证等身份证明文件，并对此进行形式审核。<br /><br />
            3、在用户发布真实合法的出行信息后，为用户提供出行信息快速匹配的服务。<br /><br />
            4、“同乡出行”为保障“同乡出行用户”安全地使用其提供的“同乡出行”客户端在线服务，将建立保护“同乡出行用户”信息(包括信用信息)的安全系统并定期进行维护。非经国家有权机关的要求，
            “同乡出行”不会向任何人泄漏“同乡出行用户”的信息。<br /><br />
            5、对“同乡出行用户”在享受“同乡出行”在线服务时遇到的问题及时响应。<br /><br />
            6、“同乡出行用户”提供的资料及数据信息(包括但不限于身份信息、个人联系方式、工作信息、住址信息;当前位置信息、目的地信息;车辆信息、驾驶证信息、行驶证信息;用户名、个性化头像、麦克风、面部识别特征等)，
            您特此授 予“同乡出行”永久的、免费的许可使用的权利，以使您更好地享受“同乡出行信息服务”。同时您特此赋予，在遵守本协议规定的隐私权保护条款的前提下，“同乡出行”获得全部或部分地使用、复制的权利。<br /><br />
            7、“同乡出行”建议“同乡出行用户”在使用合乘信息服务时遵循公益绿色拼车的原则，特别是合乘时可以以错开限行尾号、互利方便、减少交通拥堵、节能减排目的为主。
            鉴于“同乡出行”服务平台上的合乘性质为“分摊部分合乘出行成本”，双方通过“同乡出行”服务平台进行“叫车”和“接单”的行为来完成合乘服务，费用也由双方进行合理分摊。<br /><br />
            8、“同乡出行”将通过“同乡出行用户”注册时指定的邮箱、手机号码以及App手机客户端应用软件中的通知栏向“同乡出行用户”发送通知。<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            六、服务终止
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、如出现以下情形，“同乡出行”将直接以注销账户的方式终止“同乡出行用户”服务:<br /><br />
            ① “同乡出行”终止向“同乡出行用户”提供服务后，“同乡出行用户”涉嫌再一次直接或间接或以他人名义注册为“同乡出行”服务平台用户的。<br /><br />
            ② 您提供的电子邮箱或手机号码不存在或无法接收电子邮件或信息，且没有其他方式可以与您进行联系，或“同乡出行”以其它联系方式通知您更改电子邮件信息或手机号码，
            而您在通知后三个工作日内仍未更改为有效的电子邮箱或手机号码的。<br /><br />
            ③ 您注册信息中的主要内容不真实或不准确或不及时或不完整，经“同乡出行”通知后仍不修改或完善的。<br /><br />
            ④ 本协议（含规则）变更时，您明示并通知“同乡出行”不愿接受新的服务协议的。<br /><br />
            ⑤ 您的“同乡出行用户”账户首次激活后，在一年内未再使用的。<br /><br />
            ⑥ 在一年内未再使用的“同乡出行”认为应终止服务的其他情形。<br /><br />
            2、您有权向“同乡出行”要求注销“同乡出行用户”账户，经“同乡出行”审核同意的，“同乡出行”注销（永久冻结）您的“同乡出行用户”账户，届时，您与“同乡出行”之间的法律关系即时永久终止。<br /><br />
            3、在您与“同乡出行”的法律关系终止后，“同乡出行”仍享有下述权利：<br /><br />
            ① 在保护您隐私权的前提下，继续保存您的注册信息及您使用“同乡出行”服务期间的所有交易信息。<br /><br />
            ② 您在使用“同乡出行”服务期间存在违法行为或违反本协议和/或规则的行为的，“同乡出行”仍可依据本协议向您主张权利。<br /><br />
        </div>
        <div style="font-size: 15px; font-weight: bold; margin-top: 10px">
            七、协议生效及其他
        </div>
        <div style="font-size: 13px; margin-top: 10px">
            1、“不可抗力”是指“同乡出行”不能控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误“同乡出行”根据本协议条款履行其全部或部分义务。
            该事件包括但不限于政府行为、自然灾害、战争、黑客袭击、电脑病毒、网络故障等。不可抗力可能导致“同乡出行”网站及手机客户端无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄漏等不利后果。<br /><br />
            2、遭受不可抗力事件时，“同乡出行”可视情形中止/终止履行本协议条款项下的义务直至不可抗力的影响消除为止，并且不因此承担任何责任。<br /><br />
            3、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。<br /><br />
            4、本协议仅包含了“同乡出行用户”使用“同乡出行”信息服务需遵守的一般性规范，“同乡出行用户”在使用“同乡出行”服务时还须遵守适用于该平台的特殊性规范（具体请参见“同乡出行”平台“关于我们”里公示的内容）；
            若本协议或“同乡出行”平台的特殊性规范有任何修改，当您新注册或继续使用“同乡出行”信息服务时即视为对本协议或特殊规范修改的同意、认可。<br /><br />
            5、因本协议产生之争议，“同乡出行用户”与“同乡出行”首先应友好协商解决；若协商无法达成一致的，则您或“同乡出行”均有权向“同乡出行”住所地有管辖权的人民法院提起诉讼。<br /><br />
            6、您再次确认：您在同意注册成为“同乡出行用户”时，您是在认真阅读并完全理解本协议的全部内容后点击的“验证并登录”确认键。在您点击确认键时，本协议即已生效，
            对您及“同乡出行”均有法律约束力，特殊性规范另有约定的按照其约定。<br /><br />
        </div>
    </div>
</template>

<script>
    export default {
        name: "registration_agreement",
        created () {
            document.title = '注册协议'
        },
    }
</script>

<style scoped>
    .content{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
</style>
