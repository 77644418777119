<template>
    <div class="wrapper" ref="wrapper">
        <div v-for = "i in versionList" :key="i" style="width: 95%">
            <div style="display: flex; width: 100%; flex-flow: column;">
                <div class="item">
                    <div style="font-weight: bold; font-size: 20px;">
                        {{i.version}}
                    </div>
                    <div style="font-size: 15px;">
                        {{i.create_time}}
                    </div>
                </div>
            </div>
            <div style="padding: 10px;white-space: pre-wrap;" v-html="i.desc"/>
            <div style="width: 100%; height: 1px; background-color: lightgray; margin-left: 10px;"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "version_list",
        data() {
          return {
              scroll: null,
              pageNum: 1,
              totalPage: 1,
              versionList: []
          }
        },
        created () {
            document.title = '版本历史';
            this.getVersion(this.pageNum);
        },
        methods: {
            getVersion(pageNum){
                const that = this;
                const u = navigator.userAgent;
                that.isIos = u.indexOf('iPhone') > -1 || u.indexOf('iPad') > -1
                let params = {'page_num': pageNum, 'platform': that.isIos ? 'ios' : 'android'};
                this.$request.getVersionList(params).then((data) => {
                    console.log(data);
                    that.pageNum = data.current_page;
                    that.totalPage = data.total_page;
                    that.versionList = data.version_list;
                });
            },
        }
    }
</script>

<style scoped>
    .wrapper {
        display: flex;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height:100%;
        overflow:hidden;
        flex-direction: column;
    }
    .item {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
</style>
