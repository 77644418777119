import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import request from './http/HttpManager';
import Element from 'element-ui'

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(request);
Vue.use(Element, {
    size: 'small'
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
